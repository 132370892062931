import { LoadingBarRef } from "react-top-loading-bar";

// These are meant to be helper functions so that we can reduce boilerplate.

export const showLoadingBar = (loadingBarRef: React.MutableRefObject<LoadingBarRef | null>) => {
    if (loadingBarRef != null && loadingBarRef.current != null) {
        loadingBarRef.current.continuousStart();
    }
}

export const hideLoadingBar = (loadingBarRef: React.MutableRefObject<LoadingBarRef | null>) => {
    if (loadingBarRef != null && loadingBarRef.current != null) {
        loadingBarRef.current.complete();
    }
}