import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';


interface ListProps {
    isOpen?: boolean;
}


// Marton: unfortunately I had to make height px so that I can use the same number as an offset in scroll snapping.
export const NavBarContainer = styled.div`

    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 56px; 
    background-color: rgb(238,238,238);
    z-index:1;
    width:100%;

    @media (max-width: 768px) {
        position: sticky; 
        display: flex;
        align-items: center;
        box-sizing: border-box;
        background-color: rgb(238,238,238);
        height: 50%;
        z-index: 10;
        width:100%;
        align-items:center;
    
    }

`;

export const Hamburger = styled.div`
    display: none;

        @media (max-width: 768px) {
            display: block;
            font-size: 2rem;
            cursor: pointer;
            position: absolute;
            right:2%;
            top:1%;
            width:10%;
            z-index=1000;
            transform: translateY(-180%);
            

        }
`;



export const List = styled.div.attrs<ListProps>(props => ({
    isOpen: props.isOpen,
}))<ListProps>`
    display: flex;
    justify-content: center;
    margin: 2rem 0rem;
    width:100%;

    @media (max-width: 768px) {
        position: fixed;
        top: ${props => (props.isOpen ? '16%' : '-100vh')}; // Hide above the viewport
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #FFFFFF;
        width: 100%;
        padding-top:10%;
        padding-bottom:9%;
        height:500px;
        margin:0;
        display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
        transform: translateY(-100%); // Start off-screen
        transition: transform 0.9s ease-in-out;
        overflow:hidden;
        max-height:0;

        
        ${({ isOpen }) => isOpen && `
        opacity: 1;
      transform: translateY(0); 
      max-height: 300px; // Adjust as needed for your content
      visibility: visible;
    `}

    
    }
`;

export const SLink = styled(NavLink)`


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 9rem;
    height: 2rem;
    cursor: pointer;

    h4 {
        color: rgba(48, 49, 51, 0.5);
        font-size: 14px;
        font-weight: 400;
    }

    // Change style when action

    &.active {
        h4 {
            color: #000000;
        }
    }

    &:hover {
        h4 {
            color: #000000;
        }
    }

    @media (max-width: 768px) {

        display:block;
        width: 100%;
    height: 20%;

        h4{
            font-size: 25px;
            font-weight: 400;
            width:100%;

    }

   
    
`;
