import { NavBarContainer, List, SLink, Hamburger } from "./NavBar.styled";
import React, { useEffect, useState } from 'react';

function NavBar() {
    const [sticky, setSticky] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const closeNav = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleDocumentClick = (e: MouseEvent) => {
            // Close the navigation if the screen width is less than 768px
            if (window.innerWidth < 768 && isOpen) {
                setIsOpen(false);
            }
        };
    
        if (isOpen) {
            // Add event listener when the navigation is open
            document.addEventListener('click', handleDocumentClick);
        }
    
        return () => {
            // Clean up the event listener when the component is unmounted or isOpen changes
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isOpen]); // Depend on isOpen to add/remove the event listener
    
      
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    // Updates the sticky state based on how far we have scrolled
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 230 ? "sticky" : "";
        setSticky(stickyClass);
    };


    const toggleNav = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent click event from reaching the document
        setIsOpen(!isOpen);
    };
    

    return (
            <NavBarContainer className={sticky}>
             <Hamburger onClick={toggleNav}>
                {isOpen ? '✕' : '☰'} 
            </Hamburger>
                <List isOpen={isOpen}>
                    <SLink to={'/'} onClick={toggleNav}><h4>Home</h4></SLink>
                    <SLink to={'/about-fruba'} onClick={toggleNav}><h4>About</h4></SLink>
                    <SLink to={'/what-we-do'} onClick={toggleNav}><h4>What We Do</h4></SLink>
                    <SLink to={'/news'} onClick={toggleNav}><h4>News</h4></SLink>
                    <SLink to={'/contact-us'} onClick={toggleNav}><h4>Contact Us</h4></SLink>
                </List>
    
            </NavBarContainer>

    )
}

   

export default NavBar;