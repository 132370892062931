import { useEffect, useState, useRef } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import styled from 'styled-components/macro';
import { News, getAllNews, formatDate, getFilteredNews } from "../data/NewsData";
import { ToastContainer } from "react-toastify";
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { AnimatedDiv, AnimatedImage } from "../component/CommonStyledComponents";
import Search from "../component/Search";
import { Colors } from "../constants/Colors";
import { hideLoadingBar, showLoadingBar } from "../functions/Widgets";


const NewsCollection = () => {
    const [allNews, setAllNews] = useState<News[]>([]);
    const [activeQuery, setActiveQuery] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const loadingRef: React.MutableRefObject<LoadingBarRef | null> = useRef(null);

    const createQueryHeader = function () {
        const author = searchParams.get("author");
        const category = searchParams.get("category");
        const month = searchParams.get("month");
        const year = searchParams.get("year");

        const searchStr = searchParams.get("search");

        const maybeAuthorHtml = author != null ? ` Author: ${author}` : "";
        const maybecategorytml = category != null ? ` Category: ${category}` : "";
        const maybeYearHtml = year != null ? `${year}` : "";
        const maybeMonthHtml = month != null ? ` Month: ${month} ${maybeYearHtml}` : maybeYearHtml;
        const maybeSearchStrHtml = searchStr != null ? ` Search: ${searchStr}` : "";

        return (
            <>
                {[maybeAuthorHtml, maybecategorytml, maybeMonthHtml, maybeSearchStrHtml]
                    .filter((entry) => { return entry !== "" })
                    .join(" | ")}
            </>
        );
    }

    useEffect(() => {
        const author = searchParams.get("author");
        const category = searchParams.get("category");
        const month = searchParams.get("month");
        const year = searchParams.get("year");
        const searchStr = searchParams.get("search");

        showLoadingBar(loadingRef);

        if (author != null || category != null || month != null || year != null || searchStr != null) {
            const fetchNews = async () => {
                const newsData = await getFilteredNews(author, category, month, year);
                if (searchStr != null && searchStr.length > 0) {
                    const filteredNews =
                        newsData.filter((newsItem) => {
                            const lowerInput = searchStr.toLowerCase();
                            const newsItemContent = newsItem.content.rendered.toLowerCase();
                            const newsItemTitle = newsItem.title.rendered.toLowerCase();
                            return newsItemTitle.includes(lowerInput) || newsItemContent.includes(lowerInput);
                        });

                    setAllNews(filteredNews);
                } else {
                    setAllNews(newsData);
                }

                hideLoadingBar(loadingRef);
                window.scrollTo(0, 0)
            };

            fetchNews();
            setActiveQuery(true);
            return;
        }

        // Otherwise simply display all news
        const fetchNews = async () => {
            const newsData = await getAllNews(true);
            setAllNews(newsData);
            hideLoadingBar(loadingRef);
            window.scrollTo(0, 0)
        };

        fetchNews();
        setActiveQuery(false);
    }, [searchParams]);

    const getHeaderImageOrUndefined = (newsItem: News) => {
        let maybeFeaturedMedia = newsItem._embedded["wp:featuredmedia"];
        if (maybeFeaturedMedia == undefined) {
            return null;
        }

        return newsItem._embedded["wp:featuredmedia"][0].source_url;
    }

    return (
        <>
            <LoadingBar color={Colors.LoadingBarColor} ref={loadingRef} />

            <Header>
                {activeQuery ? <h3>{createQueryHeader()}</h3> : <h3>All news</h3>}
            </Header>

            {
                activeQuery && allNews.length === 0 ?
                    <NotFoundContent>
                        <h1>Nothing Found</h1>
                        <h3>Sorry, but nothing matched your search terms. Please try again with some different keywords.</h3>

                        <Search></Search>

                    </NotFoundContent >
                    :
                    <Grid>
                        {allNews.map((newsItem) => {
                            return (
                                <Card key={newsItem.id}>
                                    <StyledLink to={`/news/${newsItem.id}`}>
                                        <NewsInfoBlock>
                                            {/* Marton: I'm making an assumption that there is only ONE featured media. 
                                                However, there can be more, which is great! So you might want to change this. */}
                                            <ImageContainer>
                                                <AnimatedImage src={getHeaderImageOrUndefined(newsItem)} alt={newsItem.title.rendered} />
                                            </ImageContainer>
                                            <NewsInfo>
                                                <h5>{formatDate(newsItem.date)}</h5>
                                                <br />
                                                <h4>{newsItem.title.rendered}</h4>
                                            </NewsInfo>
                                        </NewsInfoBlock>
                                    </StyledLink>
                                </Card>
                            );
                        })}
                    </Grid >
            }

            <ToastContainer />
        </>
    );
};

const Header = styled.div`
    justify-content: center;
    background-color: rgb(243, 243, 243);
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 10px;

    h3 {
        background-color: rgb(238, 238, 238);
        color: #303133;
        font-size: 18px;
        font-weight: 600;
        line-height: 45px;

        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
`;

const NotFoundContent = styled.div`
    padding: 5% 10% 5% 10%;
    text-align: left;
    width: 100%;

    h1 {
        color: #303133;
        font-size: 52px;
        font-weight: 600;
        line-height: 1.75;
        margin: 0px 0px 0px 0px;
    }

    h3 {
        color: #777777;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.75;
        margin: 0% 0% 2% 0%;
    }
`;

const Grid = styled.div`
  width: 100%;
  padding-top: 3%;
  min-height: 75vh;
  background-color: #f3f3f3;
`;

const Card = styled.div`
    margin-bottom: 3%;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    h4 {
        text-align: left;
        align-items: center;
        color: #303133;
        font-weight: 300;
        text-decoration: none;
        font-size: 29px;
    }

    h5 {
        text-align: left;
        align-items: center;
        color: #303133;
        font-weight: 600;
        text-decoration: none;
        font-size: 12px;
    }
`;

const NewsInfoBlock = styled(AnimatedDiv)`
    display: flex; 
    flex-direction: row;
    justify-content: center;
    margin: auto;
`;

const NewsInfo = styled.div`
    background-color: rgb(238,238,238);
    padding: 60px 36px;
    width: 100%;
    height: 220px;
`;

const ImageContainer = styled.div`
    width: 40%;
    height: 220px;

    img{
        object-fit: cover;
        object-position: 0 15%;
    }
`;

const StyledLink = styled(Link)`
    display: block;
    text-decoration: none;
    max-width: 1200px;
    margin: auto;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

export default NewsCollection;
