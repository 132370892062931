import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components/macro';
import RecentPostsAndArchives from '../component/RecentPostsAndArchives';
import Search from '../component/Search';
import { MdNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { getMostRecentNews, News, formatDate, extractCategoriesFromNews, extractAuthor } from '../data/NewsData';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AnimatedDiv } from '../component/CommonStyledComponents';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { Colors } from "../constants/Colors";
import { hideLoadingBar, showLoadingBar } from "../functions/Widgets";
import { DESKTOP_WIDTH, PHONE_WIDTH } from '../constants/Dimensions';

function NewsArticle() {

    const { id } = useParams();
    const [previousNewsItem, setPreviousNewsItem] = useState<News | undefined>();
    const [currentNewsItem, setCurrentNewsItem] = useState<News>();
    const [nextNewsItem, setNextNewsItem] = useState<News | undefined>();

    const loadingRef: React.MutableRefObject<LoadingBarRef | null> = useRef(null);

    useEffect(() => {
        const currentNewsId = id!;
        showLoadingBar(loadingRef);
        const fetchNews = async () => {
            // 1) Fetch all of the news - and make sure they are ordered by date
            // 2) Select the newsItem with nonNullId - for the current page! Save the index where we found it. 
            //     (Not new ID but actual index in the array!)
            // 3) Set previous to index - 1, next to index + 1  - only if such indices exist!

            const allNewsSorted = (await getMostRecentNews(-1)).reverse();

            for (let i = 0; i < allNewsSorted.length; i++) {
                if (allNewsSorted[i].id.toString() === currentNewsId) {
                    setCurrentNewsItem(allNewsSorted[i]);

                    // Only set next and previous if they exist in the array!
                    // Note: "i" is not the same as "News.id" !!
                    //       Because there is no guaranatee that id doesn't have gaps. For example:
                    //         News.id    235  236  239  344
                    //         i            0    1    2    3
                    if (i - 1 >= 0) {
                        setPreviousNewsItem(allNewsSorted[i - 1])
                    } else {
                        setPreviousNewsItem(undefined);
                    }

                    if (i + 1 < allNewsSorted.length) {
                        setNextNewsItem(allNewsSorted[i + 1])
                    } else {
                        setNextNewsItem(undefined);
                    }

                    break;
                }
            }

            hideLoadingBar(loadingRef);
            window.scrollTo(0, 0);
        };

        fetchNews();
    }, [id]);

    // Inserts the same element between the elements of an array
    // Copied from StackOverflow :D
    const interleave = (arr: any, getSeparator: any) => [].concat(...arr.map((element: any, i: number) => [element, getSeparator(i)])).slice(0, -1)

    const getCategoryLinks = function (news: News | undefined) {
        const categories = extractCategoriesFromNews(news);
        const links = categories.map((category: any, i: number) =>
            <StyledLink key={"categoryLink" + i} to={"/news?category=" + category.name}>{category.name}</StyledLink>);

        if (links.length === 0) {
            return <span></span>;
        }

        if (links.length === 1) {
            return links;
        }

        // This is a function because I wanted to pass it a number, otherwise React complains with
        // "Each child in a list should have a unique "key" prop."
        const getSseparator = function (i: number) {
            return <span key={"separator" + i}>, </span>;
        }

        const linksWithCommas = interleave(links, getSseparator);

        return (
            <span>
                {linksWithCommas}
            </span>
        );
    };

    return (
        <>
            <LoadingBar color={Colors.LoadingBarColor} ref={loadingRef} />

            {
                currentNewsItem == undefined
                    ?
                    <></>
                    :
                    <Header>
                        <FakeBackgroundImage src={currentNewsItem.header_image_url}></FakeBackgroundImage>
                        <h3>{currentNewsItem.title.rendered}</h3>
                        <h4>
                            {formatDate(currentNewsItem.date)} | {getCategoryLinks(currentNewsItem)} |
                            By <StyledLink to={"/news?author=" + extractAuthor(currentNewsItem)}>{extractAuthor(currentNewsItem)}</StyledLink></h4>
                    </Header>
            }

            {
                currentNewsItem == undefined
                    ?
                    <></>
                    :
                    <FlexBoxContainer>
                        <FlexBox>
                            <div dangerouslySetInnerHTML={{ __html: currentNewsItem.content.rendered }}></div>
                        </FlexBox>

                        <FlexBoxSideBar>
                            <Search />
                            <RecentPostsAndArchives />
                        </FlexBoxSideBar>
                    </FlexBoxContainer>
            }


            <BottomBarContainer>
                {
                    previousNewsItem == undefined
                        ?
                        <InactivePrevNext>
                            <>
                                <MdNavigateBefore size={20} style={{ fill: '#303133' }} />
                                <h2>PREV</h2>
                            </>
                        </InactivePrevNext>
                        :
                        <PrevNext>
                            <MdNavigateBefore size={20} style={{ fill: '#303133' }} />
                            <h2><StyledLink to={"/news/" + previousNewsItem.id}>PREV</StyledLink></h2>
                        </PrevNext>
                }
                {
                    nextNewsItem == undefined
                        ?
                        <InactivePrevNext>
                            <>
                                <h2>NEXT</h2>
                                <MdOutlineNavigateNext size={20} style={{ fill: '#303133' }} />
                            </>
                        </InactivePrevNext>
                        :
                        <PrevNext>
                            <h2><StyledLink to={"/news/" + nextNewsItem?.id}>NEXT</StyledLink></h2>
                            <MdOutlineNavigateNext size={20} style={{ fill: '#303133' }} />
                        </PrevNext>
                }
            </BottomBarContainer>

            <ToastContainer />
        </>
    )
}

const FakeBackgroundImage = styled.img`
    opacity: 0.5;
    position: absolute;
    left: 0;
    /* top: 0; */
    width: 100%;
    height: auto;

    @media (min-width: ${PHONE_WIDTH}) {
        top: 20px;
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        top: 0;
    }
`

const Header = styled(AnimatedDiv)`
    overflow: hidden;
    position: relative;
    justify-content: center;
    padding: 3%;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;

    @media (min-width: ${PHONE_WIDTH}) {
    height: 130px;

    h3 {
        color: #303133;
        font-size: 30px;
        font-weight: 600;
        line-height: 1.75;
        margin: 25px 0px 0px 0px;
        position: relative;
    }

    h4 {
        color: #303133;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: .05em;
        position: relative;
        text-shadow: 0px 0px 15px white
    }
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
    height: 300px;

    h3 {
        color: #303133;
        font-size: 52px;
        font-weight: 600;
        line-height: 1.75;
        margin: 25px 0px 0px 0px;
        position: relative;
    }

    h4 {
        color: #303133;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: .05em;
        position: relative;
        text-shadow: 0px 0px 15px white
    }
    }

    
`;

const FlexBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 85%;
    margin: auto;

    max-width: 1200px;

    @media (min-width: ${PHONE_WIDTH}) {
        flex-direction: column;
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        flex-direction: row;
        height: 570px;
    }
`;

const FlexBox = styled.div`
    text-align: left;

    @media (min-width: ${PHONE_WIDTH}) {
        margin: 40px 0px 40px 0px;
        width: 100%;
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        width: 65%;
        margin: 50px;
    }

    h1 {
        color: #777777;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.75;
        margin: 18px 0px 0px 0px;
    }

    // This is a quickfix for ensuring the markers aren't rendered too much to the left.
    ul {
        padding-left: 20px;
    }

    p, ul {
        margin-bottom: 14px;
    }
`;

const FlexBoxSideBar = styled.div`
    @media (min-width: ${PHONE_WIDTH}) {
        width: 100%;
        margin: 40px 0px 40px 0px;
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        width: 30%;
    }

    margin: 50px;
    text-align: left;
`;

const BottomBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10%;
    width: 100%;
    background-color: #ffffff;
    padding: 36px 36px 36px 36px;
    
    h2 {
        color: #303133;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.1em;
    }
`;

const PrevNext = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    cursor: pointer;
    
`;

const InactivePrevNext = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    opacity: 0.5;
    cursor: default;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #303133;

    &:focus, &:visited, &:link, &:active {
        text-decoration: none;
    }

    &:hover {
        color: rgba(14, 103, 175, 0.767);
    }
`;

export default NewsArticle