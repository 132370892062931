import { useRef, useState, useEffect, useCallback } from 'react';
import Tweezer from 'tweezer.js';



function useScrollSnap({ ref = null, duration = 300, offset = 0 }) {
    const isActiveInteractionRef = useRef(null);
    const scrollTimeoutRef = useRef(null);
    const currentScrollOffsetRef = useRef(null);
    const targetScrollOffsetRef = useRef(null);
    const animationRef = useRef(null);
    const animationInProgress = useRef(null);

    // The following group of variables is for making things work like a slideshow.
    // In simple terms: scroll down = go down by one; scroll up = go up by one.
    // This makes things much easier to reason about - no need to care too much about precise offsets.
    const allElementsSorted = useRef(null);
    const currentElementNumber = useRef(null);

    const [scrollIndex, setScrollIndex] = useState(0);

    const tickAnimation = useCallback((value) => {
        const scrollTopDelta = targetScrollOffsetRef.current - currentScrollOffsetRef.current;
        const scrollTop = currentScrollOffsetRef.current + (scrollTopDelta * value / 10000);
        window.scrollTo({ top: scrollTop, behavior: 'instant' });
    }, []);

    const resetAnimation = useCallback(() => {
        animationInProgress.current = false;
        currentScrollOffsetRef.current = targetScrollOffsetRef.current;
        targetScrollOffsetRef.current = 0;
        animationRef.current = null;
    }, []);

    const endAnimation = useCallback(() => {
        if (!animationRef.current) {
            return;
        }
        animationRef.current.stop();
        resetAnimation();
    }, [resetAnimation]);

    const getAllElements = () => {
        // Need to convert HTMLCollection to native JS Array
        return [].slice.call(ref.current.children);
    }

    // Modified from https://stackoverflow.com/a/125106
    const getElementsInView = useCallback(() => {
        const elements = getAllElements();
        return elements.filter((element) => {
            let top = element.offsetTop + offset;
            const height = element.offsetHeight;
            while (element.offsetParent) {
                element = element.offsetParent;
                top += element.offsetTop;
            }
            return top < (window.scrollY + window.innerHeight) && (top + height) > window.scrollY;
        });
    }, [ref]);

    const getTargetScrollOffset = useCallback((element) => {
        let top = element.offsetTop - offset;
        while (element.offsetParent) {
            element = element.offsetParent;
            top += element.offsetTop;
        }
        return top;
    }, []);

    const snapToTarget = useCallback((target) => {
        if (animationRef.current) {
            animationRef.current.stop();
        }

        const elements = [].slice.call(ref.current.children);
        elements.forEach((element, index) => {
            if (element.isSameNode(target)) {
                setScrollIndex(index);
            }
        });

        targetScrollOffsetRef.current = getTargetScrollOffset(target);
        animationRef.current = new Tweezer({
            start: 0,
            end: 10000,
            duration: duration,
        });

        animationRef.current.on('tick', tickAnimation);
        animationRef.current.on('done', resetAnimation);

        animationInProgress.current = true;
        animationRef.current.begin();
    }, [ref, duration, getTargetScrollOffset, tickAnimation, resetAnimation]);

    // Unfortunately the animated menu doesn't play well with the scroll snap. 
    // As the menu suddenly collapses, the height of the whole website changes,
    // which can make elements jump into view without any scroll!
    const findSnapTarget = useCallback((deltaY, preventDefault) => {
        if (currentElementNumber.current == null) {
            // This -1 means header. Sadly we need to keep track of where the user is - somehow - 
            // since the user can go out of bounds.
            // Going out of bounds just means scrolling beyond the first or last elements (i.e., header, footer).
            currentElementNumber.current = -1;
            return;
        }

        // Even if we're on the boundary (i.e., header or footer area), allow the use to scroll snap 
        // - but only a single direction (i.e., down or up, respectively)
        const firstElementNumber = 0
        const weAreOnHeader = currentElementNumber.current === -1
        const weAreOnHeaderAndUserScrollDown = weAreOnHeader && deltaY > 0;
        if (weAreOnHeaderAndUserScrollDown) {
            snapToTarget(allElementsSorted.current[firstElementNumber]);
            currentElementNumber.current = firstElementNumber;
            return;
        }

        const lastElementNumber = allElementsSorted.current.length - 1
        const weAreOnFooter = currentElementNumber.current === allElementsSorted.current.length
        const weAreOnFooterAndUserScrolledUp = weAreOnFooter && deltaY < 0;
        if (weAreOnFooterAndUserScrolledUp) {
            snapToTarget(allElementsSorted.current[lastElementNumber]);
            currentElementNumber.current = lastElementNumber;
            return;
        }

        const weAreOnFirstElement = currentElementNumber.current === firstElementNumber
        if (weAreOnFirstElement && deltaY < 0) {
            // Entering header!
            currentElementNumber.current = -1;
            return;
        }


        const weAreOnLastElement = currentElementNumber.current === lastElementNumber
        if (weAreOnLastElement && deltaY > 0) {
            // Entering footer!
            currentElementNumber.current = allElementsSorted.current.length;
            return;
        }

        if ((weAreOnFooter && deltaY > 0) || (weAreOnHeader && deltaY < 0)) {
            return;
        }

        if (deltaY < 0) {
            const indexTarget = currentElementNumber.current - 1;
            if (indexTarget < 0) {
                // There's nothing before the first element.
                return;
            }

            preventDefault();

            snapToTarget(allElementsSorted.current[indexTarget]);
            currentElementNumber.current = indexTarget;

        } else if (deltaY > 0) {
            const indexTarget = currentElementNumber.current + 1;
            if (indexTarget == allElementsSorted.current.length) {
                // There's nothing after the last element.
                return;
            }

            preventDefault();

            snapToTarget(allElementsSorted.current[indexTarget]);
            currentElementNumber.current = indexTarget;
        }

        currentScrollOffsetRef.current = window.scrollY;
    }, [getElementsInView, snapToTarget]);

    const onInteractionStart = useCallback(() => {
        endAnimation();
        isActiveInteractionRef.current = true;
    }, [endAnimation]);

    const onInteractionEnd = useCallback((e) => {
        isActiveInteractionRef.current = false;
        findSnapTarget();
    }, [findSnapTarget]);

    const onInteraction = useCallback((e) => {
        const preventDefault = () => {
            e.preventDefault();
            e.stopPropagation();
        };

        if (animationInProgress.current) {
            preventDefault();
            return;
        }

        const deltaY = e.deltaY;
        if (scrollTimeoutRef) {
            clearTimeout(scrollTimeoutRef.current);
        }

        if (isActiveInteractionRef.current || animationRef.current) {
            return;
        }

        endAnimation();

        scrollTimeoutRef.current = setTimeout(() => findSnapTarget(deltaY, preventDefault), 10);
    }, [endAnimation, findSnapTarget]);

    useEffect(() => {
        if (ref) {
            resetAnimation();
            allElementsSorted.current = getAllElements();
            animationInProgress.current = false;

            document.addEventListener('keydown', onInteractionStart, { passive: true });
            document.addEventListener('keyup', onInteractionEnd, { passive: true });
            document.addEventListener('touchstart', onInteractionStart, { passive: true });
            document.addEventListener('touchend', onInteractionEnd, { passive: true });
            document.addEventListener('wheel', onInteraction, { passive: false });

            findSnapTarget(0, () => { });

            return () => {
                endAnimation();

                document.removeEventListener('keydown', onInteractionStart, { passive: true });
                document.removeEventListener('keyup', onInteractionEnd, { passive: true });
                document.removeEventListener('touchstart', onInteractionStart, { passive: true });
                document.removeEventListener('touchend', onInteractionEnd, { passive: true });
                document.removeEventListener('wheel', onInteraction, { passive: true });
            }
        }
    }, [
        ref,
        resetAnimation,
        findSnapTarget,
        endAnimation,
        onInteractionStart,
        onInteractionEnd,
        onInteraction
    ]);

    return scrollIndex;
}

export default useScrollSnap;