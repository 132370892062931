import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { PHONE_WIDTH, DESKTOP_WIDTH } from './constants/Dimensions';

export const Logo = styled(Link)`
    text-decoration: none;

    @media (min-width: ${PHONE_WIDTH}) {
        img{
            width: 55%;
            height: 100%;
            
        }
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        img{
            width: 70%;
        }
    }
`

export const LogoContainer = styled.div`
    padding: 2rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    svg {
        font-size: 2rem;
    }

    @media (max-width: 768px) {
        flex-grow: 1;         // This allows the container to grow and center the logo
        height: 15vh;        
        max-width:100%;   
}
`

export const Nav = styled.div`
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
    justify-content: center;  
    align-items: center;
    width: 100%;               
    padding: 0 20px;          
    margin:0;
    }
    
`