import { toast } from "react-toastify";
import { ServiceUrls } from "./ServiceUrls";

interface CustomUserFields {
    // This is the ID of the media
    profile_image: number | undefined;
    active: boolean;
    biography: string;
    linkedin_url: string;
}

export interface UserData {
    name: string;
    id: string;

    // Custom fields that were added by the ACF plugin on the WordPress dashboard.
    acf: CustomUserFields;

    profile_image_url: string;
}

export const fetchAllActiveUsers = async (): Promise<UserData[]> => {
    try {
        const usersResponse = fetch(ServiceUrls.Users)
            .then(response => {
                if (!response.ok) {
                    const errorMsg = 'Could not fetch users due to: ' + response.status + ' ' + response.statusText;
                    toast.error(errorMsg);
                    throw new Error(errorMsg);
                }

                return response.json();
            });

        const responses = await Promise.all([usersResponse]);
        const users: UserData[] = responses[0];
        const activeUsers = users.filter((user) => user.acf.active);

        return activeUsers;

    } catch (error) {
        console.error(error);
        return [];
    }
};
