import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
    return (
        <>
            <TitleContainer>
                <h1>Privacy Policy</h1>
            </TitleContainer>
            <ContentContainer>
                <h2>
                    1. Name and contact data of the data controller
                </h2>
                <h3>
                    This Privacy Policy covers data processing by:
                    <br />
                    Data Controller:
                    <br />
                    <br />
                    Fruba IP Consulting OÜ
                    <br />
                    Sepapaja tn 6 Lasnamäe linnaosa
                    <br />
                    Tallinn Harju maakond
                    <br />
                    15551
                    <br />
                    Estonia
                    <br />
                    <br />
                    (the aforesaid company hereinafter referred to as: “Fruba” or “we / our / us”),
                    <br />
                    <br />
                    Data protection related requests can be sent to the aforementioned address as well as under the following email address:
                    <br />
                    <br />
                    info@fruba-ip.com
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    2. Collection and storage of personal data and also nature and purpose and their use</h2>
                <h3>
                    a) When visiting the website<br />
                    When you access our website fuba-ip.com, the browser on your end device automatically sends information to our website server.When you do so, we only record the IP address of the requesting computer, however the last octet (8 bits) will be truncated and the IP address replaced by a generic IP address.This information is temporarily saved in a log file.The following information is collected without any action on your part and deleted automatically after four weeks:<br />
                    ● IP address of the querying computer,<br />
                    ● date and time of the access,<br />
                    ● name and URL of the accessed file,<br />
                    ● website from which the access was made (referrer URL),<br />
                    ● browser type and version and also further information sent by the browser (such as your computer’s operating system, the name of your access provider, geographical origin, language setting etc.).
                    <br />
                    <br />
                    We process these data for the following purposes:<br />
                    ● ensuring trouble-free connection to the website,<br />
                    ● ensuring comfortable use of our website,<br />
                    ● evaluating system security and stability and also<br />
                    ● for further administrative purposes.<br />
                    <br />
                    The legal grounds for the data processing depend on the jurisdiction where the data is processed, and they include Art. 6 Subs. 1 Sentence 1 lit. f General Data Protection Regulation (“GDPR”), where applicable. Our legitimate interest follows from the above purposes for the data collection. Under no circumstances do we use the collected data for the purpose of drawing any conclusions about your person.
                    <br />
                    <br />
                    In addition, we also use cookies and analytics services during visits to our website. Further explanations can be found under sections 4 and 5 of this Privacy Policy.
                    <br />
                    <br />
                    b) When using our contact form<br />
                    You may get in touch with us via a form available in the website contact information. This requires providing a valid email address, your first and last name and your company name.
                    <br />
                    <br />
                    The usage of our contact form is handled through our own server. Messages will be sent encrypted with the customary and secure SSL standard (Secure Socket Layer).
                    <br />
                    <br />
                    The data for the purpose of contacting us are processed under Art. 6 Subs. 1 Sentence 1 lit. a and f GDPR on the basis of data subject's consent and our legitimate interests.
                    <br />
                    <br />
                    The personal data we collected for using the contact form will be deleted after your query has been dealt with.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    3. Transfer of data to third parties
                </h2>
                <h3>
                    Your personal data will not be transferred to third parties for purposes other than those specified below.
                    <br />
                    <br />
                    a) For execution of the contract
                    <br />
                    Insofar as permitted by law and in accordance with Art. 6 Para. 1 sentence 1 lit. b GDPR is required for the settlement of the contractual relationship with you, your personal data will be passed on to third parties. This includes, in particular, payment service providers.
                    <br />
                    <br />
                    The transferred data may be used by the third party solely for the purpose of their disclosure.
                    <br />
                    <br />
                    b) For other purposes
                    <br />
                    Apart from that we will only transfer your personal data to third parties, if:
                    <br />
                    <br />
                    you have expressly given your consent pursuant to Art. 6 Para. 1 S. 1 lit. a GDPR; in cases where transfer of your data is necessary for compliance with a legal obligation pursuant to 6 Para. 1 S. 1 lit. c GDPR; this is necessary for the protection of the Controller’s legitimate interests or those of a third party (Art. 6 Para. 1 lit. f GDPR or other applicable laws). The data processing is necessary in particular for the establishment, exercise or defence of legal claims and where there seems to be no reason to assume that you have an overriding and legitimate interest in preventing the transfer of your data.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    4. Cookie Policy
                </h2>
                <h3>
                    We use cookies on our website. These are small files that your browser automatically creates and saves on your end device (laptop, tablet, smartphone or suchlike) when you visit our website. Cookies do not cause any harm to your computer and do not contain any viruses, trojans or other malware.
                    <br />
                    <br />
                    The cookie stores information which arises in conjunction with the specifically used end device. This does not mean, however, that this gives us direct knowledge of your identity.
                    <br />
                    <br />
                    Cookies are used on the one hand so that we can make the use of our offerings more pleasant for you. Therefore, we use session cookies to recognise that you have already visited individual pages on our website. These are automatically deleted after you leave our website.
                    <br />
                    <br />
                    In addition, we use temporary cookies saved on your end device for a certain defined period to optimise user friendliness. If you visit our website again to use our services, it is automatically recognised that you were already here before and which entries and settings you made so that you do not have to repeat them.
                    <br />
                    <br />
                    The data processed by cookies are required for the stated purposes to protect our legitimate interests and also of third parties under Art. 6 Subs. 1 Sentence 1 lit. f GDPR.
                    <br />
                    <br />
                    All cookies we use are required for the essential maintenance of the website. None of the cookies we use transfer sensitive information to third parties.
                    <br />
                    <br />
                    Most browsers accept cookies automatically. You can configure your browser, however, so that no cookies are saved on your computer or a message always appears before a new cookie is created. Complete deactivation of cookies can, however, lead to you not being able to use all the functions of our website.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    5. Analytical tools
                </h2>
                <h3>
                    We are not using any tracking and targeting measures.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    6. Log data
                </h2>
                <h3>
                    Servers keep the records of traffic and requests coming to our site. This is called Log data.L Log data is the information recorded by our web server about when, how, and which visitors are using our website. Web server providers collect the following information about you:
                    <br />
                    <br />
                    IP address and device identifier: This is the unique identifying address broadcasted by the browser or device by which you are accessing our online platform.
                    <br />
                    Request date/time: The specific date and time of each action taken by you.
                    <br />
                    Page requested: Each page or interface that you access and how long you spend on each page.
                    <br />
                    Bytes served: How much information is transferred during your activity.
                    <br />
                    User agent: Commonly, your web browser or operating system which transfers necessary information to the web server in order to serve your needs.
                    <br />
                    Referrer: The previous location that you were at before you referred to our website or app, such as a search engine or exterior website link.
                    Error logs: Information regarding any errors or functionality problems experienced by you.
                    <br />
                    <br />
                    Once the log data has been collected, it will be organized by type so that the web server knows how to use the information. Log data may be organized according to its potential usefulness in categorical logs such as error logs, access logs, and server logs. The log data will be deleted after 1 month.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    7. Social media
                </h2>
                <h3>
                    a) LinkedIn
                    <br />
                    <br />
                    Our website implements links but no plugins from LinkedIn Unlimited Company Network, Wilton Plaza, Wilton Place, Dublin 2, Ireland (“LinkedIn”).
                    <br />
                    <br />
                    When you visit a page on our website, you will not be connected to LinkedIn’s servers. If you click on the appropriate button, they will be redirected to a LinkedIn website. We have no control over the processing of personal data on third-party websites.
                    <br />
                    <br />
                    This information is sent from your browser directly to a LinkedIn server in the United States and stored there. LinkedIn complies with the privacy policy of the US Privacy Shield and is registered with the U.S. Department of Commerce’s US Privacy Shield Program.
                    <br />
                    <br />
                    By visiting the LinkedIn website, LinkedIn receives the information that you have visited its page with your IP address and other log data. This log data includes browser type, operating system, information about the previously visited website and the pages you visited, location, your wireless service provider, device information (including device ID and application ID), search terms, and cookie information. In addition, LinkedIn uses tracking and targeting tools to optimize the site and tailor it to users’ needs.
                    <br />
                    <br />
                    For more information, see the LinkedIn privacy statement.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    8. Data subject rights
                </h2>
                <h3>
                    You have the right:<br />
                    ● pursuant to Art. 15 GDPR to demand information about your personal data we process. In particular, you can demand information about the purposes of the processing, the category of the personal data, the categories of recipients to whom your data were or will be disclosed, the planned storage period, the existence of a right to rectification, deletion, restriction or revocation of processing, the existence a right to lodge a complaint, the origin of your data, in so far as not collected by us, and also about the existence of automated decision-making including profiling and where appropriate meaningful information about to details thereof;
                    <br />
                    <br />
                    ● pursuant to Art. 16 GDPR to demand immediate rectification of inaccurate or completion of your personal data saved with us;
                    <br />
                    <br />
                    ● pursuant to Art. 17 GDPR to demand deletion of your personal data saved with us, in so far as the processing is not required for exercising the right of freedom of expression and information, to comply with a legal obligation, for reasons of public interest or to establish, exercise or defend legal claims;
                    <br />
                    <br />
                    ● pursuant to Art. 18 GDPR to demand restriction of processing of your personal data, in so far as you contest the accuracy of the data, the processing is unlawful but you oppose deletion and we no longer need the data but you do to establish, exercise or defend legal claims or you have objected to processing pursuant to Art. 21 GDPR;
                    <br />
                    <br />
                    ● pursuant to Art. 20 GDPR to receive your personal data you have provided us in a structured, commonly used and machine-readable format or to demand transmission to another controller;
                    <br />
                    <br />
                    ● pursuant to Art. 7 Subs. 3 GDPR to withdraw your consent to us at any time. This means that we may no longer continue processing the data based on that consent for the future and
                    <br />
                    <br />
                    ● pursuant to Art. 77 GDPR to lodge a complaint to a supervisory authority. As a rule, you can contact the supervisory authority for your habitual residence or place of work or our registered offices.
                    <br />
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    9. Right to object
                </h2>
                <h3>
                    In so far as your personal data are processed on the basis of legitimate interests pursuant to Art. 6 Subs. 1 Sentence 1 lit. f GDPR, you have the right, pursuant to Art. 21 GDPR, to object to the processing of your personal data, in so far as there are grounds arising from your particular situation or it relates to objection to direct advertising. In the latter case, you have a general right to object which we shall heed without the stating of a particular situation.
                    <br />
                    <br />
                    If you want to exercise your right to object, simply send an email to dataprotection@fruba-ip.com.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    10. Data Security
                </h2>
                <h3>
                    All the data you personally transfer will be sent encrypted with the customary and secure SSL standard (Secure Socket Layer). SSL is a secure and proven standard, which is also used for online banking, for example.
                    <br />
                    <br />
                    We also use suitable technical and organisational security measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction or against unauthorised access by third parties. Our security measures are continually monitored and improved to reflect technological developments.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    11. Actuality of and changes to this Privacy Policy
                </h2>
                <h3>
                    This Privacy Policy is the latest version and was last amended as of January 2024.<br />
                    <br />
                    The further development of our website and offers on it or changes in statutory or public-authority requirements may render it necessary to amend this Privacy Policy. The latest version of Privacy Policy can be downloaded and printed out at any time from the website under: fruba-ip.com.
                    <br />
                    <br />
                    <br />
                    <br />
                </h3>
            </ContentContainer>
        </>
    )
}

const TitleContainer = styled.div`
    width: 100%;
    padding: 36px;
    text-align: center;

    h1 {
        color: #000000;
        font-size: 52px;
        font-weight: 300;
    }
`;

const ContentContainer = styled.div`
    width: 100%;
    text-align: center;
    background-color: #bbcbd7;
    margin: auto;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 30px;

    h2 {
        color: #303133;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.75;
        text-align: left;
    }

    h3 {
        color: #777777;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.75;
        margin: 18px 0px 0px 0px;
        text-align: left;
    }
`;

const SLink = styled(Link)`
    color: #777777;
    text-decoration: none;
    cursor: pointer;

    &.active {
        h4 {
            color: rgba(48, 49, 51, 0.5);
        }
    }
`;

export default PrivacyPolicy