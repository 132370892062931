import { toast } from "react-toastify";
import { groupBy } from "../functions/Collections";
import { ServiceUrls } from "./ServiceUrls";

export interface RenderedContent {
    rendered: string
}

export interface RenderedTitle {
    rendered: string
}

interface CustomNewsFields {
    // This is the ID of the media
    header_image: number | undefined;
}

export interface News {
    title: RenderedTitle;
    author: string;
    id: string;
    date: string;
    content: RenderedContent;

    header_image_url: string | undefined;

    // Custom fields that were added by the ACF plugin on the WordPress dashboard.
    acf: CustomNewsFields;
    _embedded: any;
}

export function extractAuthor(news: News | undefined) {
    return news?._embedded.author[0].name;
}

interface CachedNews {
    news: News[];
    // Milliseconds since 1970-01-01
    expiry: number;
}

// Default to 5 minutes
// I don't know of a TimeSpan type in TypeScript
const newsCacheExpiryMilliseconds = 5 * 60 * 1000;
const newsCacheKey = "allNewsV6"

const fetchAllNewsFromServer = async (): Promise<News[]> => {
    try {
        const newsResponse = fetch(ServiceUrls.News)
            .then(response => {
                if (!response.ok) {
                    const errorMsg = 'Could not fetch news due to: ' + response.status + ' ' + response.statusText;
                    toast.error(errorMsg);
                    throw new Error(errorMsg);
                }

                return response.json();
            });

        const responses = await Promise.all([newsResponse]);
        const news: News[] = responses[0];

        return news;

    } catch (error) {
        console.error('Error fetching news:', error);
        return [];
    }
};

const fetchAndStoreAllNews = async (): Promise<News[]> => {
    const allNews = await fetchAllNewsFromServer();
    const expiry = new Date().getTime() + newsCacheExpiryMilliseconds;
    const newsCacheEntry: CachedNews = { news: allNews, expiry: expiry };
    localStorage.setItem(newsCacheKey, JSON.stringify(newsCacheEntry));
    return allNews;
}

export const getAllNews = async (forceRefresh: boolean = false): Promise<News[]> => {
    if (forceRefresh) {
        return await fetchAndStoreAllNews();
    }

    const existingCachedNewsJson = localStorage.getItem(newsCacheKey);
    if (existingCachedNewsJson == null) {
        return await fetchAndStoreAllNews();
    }

    const cachedNews: CachedNews = JSON.parse(existingCachedNewsJson);
    const now = new Date().getTime();
    if (now > cachedNews.expiry) {
        return await fetchAndStoreAllNews();
    }

    return cachedNews.news;
};

export const getFilteredNews = async (author: string | null, category: string | null, month: string | null, year: string | null): Promise<News[]> => {
    try {
        let result = await getAllNews();
        if (author != null) {
            result = result.filter((news) => { return news._embedded.author[0].name === author });
        }

        if (category != null) {
            result = result.filter((newItem) => {
                // Maps the newsItem's category objects to strings
                const categoriesOfNewsItem = newItem._embedded["wp:term"][0].map((categoryObj: any) => categoryObj.name)
                // So that we can easily check if the currently selected category is among them
                return categoriesOfNewsItem.includes(category)

            });
        }

        if (month != null) {
            result = result.filter((news) => { return getMonthNameFromDateStr(news.date) === month })
        }

        if (year != null) {
            const yearNum = parseInt(year)
            result = result.filter((news) => { return getYearFromDateStr(news.date) === yearNum })
        }

        return result;
    } catch (error) {
        console.error('Error querying news:', error);
        return [];
    }
}

function dateComparison(a: any, b: any) {
    if (a.date < b.date) {
        return 1;
    }
    if (a.date > b.date) {
        return -1;
    }
    return 0;
}

export const getMostRecentNews = async (n: number): Promise<News[]> => {
    try {
        const allNews = await getAllNews();
        const newsSortedByDate = allNews.sort(dateComparison);

        // This is mainly just error handling
        if (n > 0 && n <= newsSortedByDate.length) {
            const mostRecentNews = newsSortedByDate.slice(0, n);
            return mostRecentNews
        }

        // If n didn't even exist (e.g. negative number, or something huge like 1000 when they are only 5 items)
        // Then return all of the items sorted by date. 
        return newsSortedByDate;
    } catch (error) {
        console.error('Error fetching latest N news:', error);
        return [];
    }
}

export const getNewsWithId = async (id: string): Promise<News | undefined> => {
    try {
        const allNews = await getAllNews();
        const selectedNews = allNews.find((news) => news.id === id);
        return selectedNews;
    } catch (error) {
        console.error('Error fetching news with id ' + id.toString(), error);
        throw error;
    }
}

export class ArchiveMonth {
    Year: number;
    Month: string
    NumResults: number

    constructor(year: number, month: string, numResults: number) {
        this.Year = year;
        this.Month = month;
        this.NumResults = numResults
    }
}

// This function expects monthNumber to be 1 to 12 !!!
// Note that when you call date.getMonth() on an in-built date, that returns zer indexed month numbers though.
function getMonthName(monthNumber: number) {
    const date = new Date();
    // Becdause this works with zer indexed numbers.
    date.setMonth(monthNumber - 1);

    // Using the browser's default locale.
    const monthName = date.toLocaleString([], { month: 'long' });
    return monthName;
}

function getMonthNameFromDateStr(dateStr: string) {
    const date = new Date(dateStr);
    return getMonthName(date.getMonth() + 1);
}

function getYearFromDateStr(dateStr: string) {
    const date = new Date(dateStr);
    return date.getFullYear();
}

export function formatDate(dateStr: string | undefined) {
    if (dateStr == null) {
        return "";
    }

    const date = new Date(dateStr);
    // The +1 is needed because .getMonth returns zero indexed month numbers
    return ` ${date.getDay()} ${getMonthName(date.getMonth() + 1)} ${date.getFullYear()} `
}

export function extractCategoriesFromNews(news: News | undefined) {
    if (news == null) {
        return [];
    }

    if (news._embedded["wp:term"][0].length === 0) {
        return [];
    }

    return news._embedded["wp:term"][0];
}

export const getAllArchiveMonths = async (): Promise<ArchiveMonth[]> => {
    try {
        const allNews = await getAllNews();
        const groupedNewsItems: { [id: string]: News[]; } = allNews.reduce((newsItemsSoFar: any, newsItem) => {
            const dateParts = newsItem.date.split("-");
            const yearMonth = dateParts[0] + "-" + dateParts[1];
            if (!newsItemsSoFar[yearMonth]) {
                newsItemsSoFar[yearMonth] = [];
            }
            newsItemsSoFar[yearMonth].push(newsItem);
            return newsItemsSoFar;
        }, {});

        const archiveMonths = [];
        for (const [key, value] of Object.entries(groupedNewsItems)) {
            const dateParts = key.split("-");
            const year = parseInt(dateParts[0])
            const month = getMonthName(parseInt(dateParts[1]));
            archiveMonths.push(new ArchiveMonth(year, month, value.length));
        }

        return archiveMonths;

    } catch (error) {
        console.error('Error fetching latest N news:', error);
        return [];
    }
}