import styled from 'styled-components/macro';

// The idea is that you can change the animations below, 
// and your change will apply consistently to the entire website.

export const AnimatedImage = styled.img`
    animation: popIn 0.5s ease-out forwards;
`;

export const AnimatedDiv = styled.div`
    animation: popIn 0.5s ease-out forwards;
`;