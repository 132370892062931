import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Logo, LogoContainer, Nav } from './App.styled';
import './App.css';
import NavBar from './component/NavBar';
import PagesRouters from './pages/Pages';
import Footer from './component/Footer';
import { AnimatedImage } from './component/CommonStyledComponents';

function App() {
    const [logoSrc, setLogoSrc] = useState("http://fruba-ip.com/wp-content/uploads/2023/12/fruba_logo-4_fruba_logo-logo-full-color-rgb.svg");

    const CookieConsent = () => {
        const [isVisible, setIsVisible] = useState(false);

        useEffect(() => {
            const consent = getCookie("cookieConsent");
            if (!consent) {
                setIsVisible(true);
            }
        }, []);

        const handleAccept = () => {
            setCookie("cookieConsent", "accepted", 365); // Store consent for 365 days
            setIsVisible(false);
        };

        return isVisible ? (
            <div className='cookieConsent' >
                <p className='cookieConsentTitle'> Our use of cookies<br /></p>
                <p className='cookieText'> We use necessary cookies to make our site work. Necessary cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.<br />
                    <br />Your continuing to browse our website signifies your agreement to our use of the cookies. For more detailed information about the cookies we use, see our <a href="/privacy">Privacy Policy</a>.<br />
                </p>
                <p><br /></p>
                <button className='cookieAcceptButton' onClick={handleAccept}>Accept</button>
            </div>
        ) : null;
    };

    function setCookie(name: string, value: string, days: number) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name: string): string | null {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length);
        }
        return null;
    }





    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 480) {
                setLogoSrc("http://fruba-ip.com/wp-content/uploads/2023/12/fruba_logo-logo-full-color-rgb-horizontal.svg");
            } else {
                setLogoSrc("http://fruba-ip.com/wp-content/uploads/2023/12/fruba_logo-4_fruba_logo-logo-full-color-rgb.svg");
            }
        }

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return (
        <div className="App">
            <CookieConsent />
            <BrowserRouter>
                <Nav>
                    <LogoContainer>
                        <Logo to={'/'}>
                            <AnimatedImage className="logoimage" src={logoSrc} alt="Fruba IP Consulting" />
                        </Logo>
                    </LogoContainer>
                    <NavBar />
                </Nav>
                <PagesRouters />
                <Footer />
            </BrowserRouter>
        </div>
    );
}


export default App;



