import styled from 'styled-components/macro';
import { useEffect, useRef } from 'react';
import React, { useState } from 'react';
import './ContactUs.css';
import '../index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceUrls } from '../data/ServiceUrls';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { Colors } from "../constants/Colors";
import { hideLoadingBar, showLoadingBar } from "../functions/Widgets";

{/* <meta name="viewport" content="width=device-width, initial-scale=1"></meta> */ }

interface IContactForm {
    visitorName: string;
    visitorEmail: string;
    visitorCompany?: string;
    visitorMessage: string;
    acceptPolicy: boolean;
}

const ContactSection: React.FC = () => {
    return (
        <div className="letusknow">

            Let us know your ideas and questions. We will revert shortly.

        </div>
    );
};

const ContactForm: React.FC = () => {
    const [form, setForm] = useState<IContactForm>({
        visitorName: '',
        visitorEmail: '',
        visitorCompany: '',
        visitorMessage: '',
        acceptPolicy: false,
    });

    const loadingRef: React.MutableRefObject<LoadingBarRef | null> = useRef(null);

    const toFormData = (contactData: IContactForm) => {
        try {
            const data = new FormData()

            // These keys can be found on the WordPress dashboard: Contact -> Contact Forms -> Your form's name -> Mail
            data.append("your-name", contactData.visitorName);
            data.append("your-email", contactData.visitorEmail);
            data.append("your-company", contactData.visitorCompany ?? "N/A");
            data.append("your-message", contactData.visitorMessage);
            data.append("accept-this-1", contactData.acceptPolicy.toString());

            return data
        } catch (error) {
            console.error(error)
            return null
        }
    }


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Perform validation
        if (!form.acceptPolicy) {
            toast.error('Please accept the Privacy Policy.');
            return;
        }

        if (!form.visitorName || !form.visitorEmail || !form.visitorMessage) {
            toast.error('Please fill out all required fields.');
            return;
        }

        // Assuming you have a function to send data to the server
        sendFormDataToServer(form);

        // Reset the form
        setForm({
            visitorName: '',
            visitorEmail: '',
            visitorCompany: '',
            visitorMessage: '',
            acceptPolicy: false,
        });
    };

    const sendFormDataToServer = (visitorDetails: IContactForm) => {
        // Here, you can implement the code to send formData to the server, e.g., making an API request.
        console.log('Sending form data to server:', visitorDetails);
        showLoadingBar(loadingRef);

        fetch(ServiceUrls.Contact, {
            method: "POST",
            body: toFormData(visitorDetails)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                // TODO: we haven't tested this yet.
                if (resp.status !== "mail_sent") {
                    throw resp.message;
                }

                toast.success("Success! Mail has been sent.");
            })
            .catch((error) => {
                toast.error(error);
            })
            .finally(() => {
                hideLoadingBar(loadingRef);
            })
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prevForm) => ({ ...prevForm, acceptPolicy: e.target.checked }));
    };

    useEffect(() => {
        // We want to start on top. This is normally not the case on refresh. So we want to force it.
        // However due to various animations/changing screen height, it doesn't work without the delay.
        // So the following is a hack that seems to work most of the time.
        setTimeout(() => window.scrollTo(0, 0), 200);
    }, []);

    return (
        <form action="/contact-us/#wpcf7-f16-p50-o1"
            method="post"
            className="form"
            aria-label="Contact form"
            noValidate
            onSubmit={handleSubmit}>

            <p className="input_field">
                <input className="input_field_space"
                    aria-required={true}
                    aria-invalid={false}
                    placeholder="Name" value={form.visitorName}
                    type="text" name="visitorName"
                    onChange={handleChange} />
            </p>
            <br></br>
            <p className="input_field">
                <input className="input_field_space"
                    aria-required={true}
                    aria-invalid={false}
                    placeholder="Email"
                    value={form.visitorEmail}
                    type="email"
                    name="visitorEmail"
                    onChange={handleChange} />
            </p>
            <br></br>
            <p className="input_field">
                <input className="input_field_space"
                    aria-invalid={false}
                    placeholder="Company (optional)"
                    value={form.visitorCompany || ''}
                    type="text"
                    name="visitorCompany"
                    onChange={handleChange} />
            </p>
            <br></br>
            <p className="input_field">
                <textarea className="input_field_space_large"
                    aria-required={true}
                    aria-invalid={false}
                    placeholder="Enter your message here"
                    name="visitorMessage"
                    value={form.visitorMessage}
                    onChange={handleChange}></textarea>
            </p>
            <p className="privacy_check">
                <label>
                    <input type="checkbox"
                        name="acceptPolicy"
                        aria-invalid={false}
                        onChange={handleCheckboxChange}
                        value={form.acceptPolicy ? 1 : 0}
                        checked={form.acceptPolicy} />
                    <span> Check here to accept our <a href="https://fruba-ip.com/privacy/">Privacy Policy</a></span>
                </label>
            </p>
            <p>
                <input type="submit" className="contact_submit_button" value="Submit" />

            </p>
            <div aria-hidden={true}></div>
            <br></br>

            <ToastContainer />
            <LoadingBar color={Colors.LoadingBarColor} ref={loadingRef} />
        </form>
    );
};

const TwoColumnLayoutPage: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className="layout">
            <div className="column-1">
                <img
                    decoding="async"
                    className={isVisible ? 'contact-us-pic fly-in' : 'contact-us-pic'}
                    src="https://fruba-ip.com/wp-content/uploads/2021/12/Screenshot-2021-12-08-at-3.05.50-PM-uai-1032x1147.png"
                    alt="Contact Fruba"
                />
            </div>
            <div className="column-2">
                <ContactSection />
                <ContactForm />
            </div>
        </div>
    );
};

export default TwoColumnLayoutPage;
