import styled from 'styled-components/macro';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Search = () => {
    const [input, setInput] = useState("");
    const navigate = useNavigate();
    const handleSubmit = (event: any) => {
        // This is basically a hack to prevent the HTML form from refreshing the page.
        event.preventDefault();
        navigate(`/news?search=${input}`);
    };

    return (
        <FormStyle onSubmit={handleSubmit}>
            <div>
                <h1>Search</h1>
                <FlexBoxContainer>
                    <input
                        onChange={(event) => setInput(event.target.value)}
                        type="search"
                        value={input}
                        required
                    />
                    <Button>
                        <h2>SEARCH</h2>
                    </Button>
                </FlexBoxContainer>
            </div>
        </FormStyle>
    );
};

const FormStyle = styled.form`
  /* margin: 0rem 20rem; */
  div {
        width: 100%;
        position: relative;
    }
    input {
        border: none;
        background: #f7f7f7;
        font-size: 1.5rem;
        color: #777777;
        padding: 8px;
        outline: none;
        width: 100%;
        font-size: 15px;
        border-radius: 2px;
        height: 35px;

    }
    h1 {
        color: #777777;
        font-size: 15px;
        font-weight: 400;
        margin: 0px 0px 5px 0px;
    }   
`;

const FlexBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 50px;
    width: 30%;
    margin: auto;
    gap: 10px;
`;

const Button = styled.button`
    font-weight: 600;
    letter-spacing: 0.1em;
    background-color: #f7f7f7;
    color: #777777;
    padding: 5px 15px;
    font-size: 12px;
    border: none;
    border-radius: 2px;
    height: 35px;
    cursor: pointer;

    h2 {
        color: #777777;
        font-size: 12px;
        font-weight: 600;
    }   
`;

export default Search;
