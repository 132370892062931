import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import { PHONE_WIDTH, DESKTOP_WIDTH } from '../constants/Dimensions';

function Footer() {
    return (
        <>
            <FooterLink>
                <List>
                    <SLink to={'/'}>
                        <h4>HOME</h4>
                    </SLink>
                    <SLink to={'/about-fruba'}>
                        <h4>ABOUT</h4>
                    </SLink>
                    <SLink to={'/what-we-do'}>
                        <h4>WHAT WE DO</h4>
                    </SLink>
                    <SLink to={'/news'}>
                        <h4>NEWS</h4>
                    </SLink>
                    <SLink to={'/contact-us'}>
                        <h4>CONTACT US</h4>
                    </SLink>
                    <SLink to={'https://www.linkedin.com/company/77129888/admin/'}>
                        <FaLinkedin size={30} style={{ fill: 'black' }} />
                    </SLink>
                </List>

                <List>
                    <SLink to={'/imprint'}>
                        <h4>LEGAL</h4>
                    </SLink>
                    <SLink to={'/privacy'}>
                        <h4>PRIVACY POLICY</h4>
                    </SLink>
                </List>

                <Logo to={'/'}><img src="https://fruba-ip.com/wp-content/uploads/2021/12/k.png" alt="Fruba IP Consulting" height="60" /></Logo>
            </FooterLink>

            <CopyrightContainer>
                <h4>©️ 2024 FRUBA. All rights reserved.</h4>
            </CopyrightContainer>
        </>
    )
}

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px; //row gap
`;

const SLink = styled(NavLink)`
    display: flex;
    flex-direction: row;
    margin-right: 2rem;
    text-decoration: none;
    width: 15rem;
    height: 2rem;
    list-style: circle;
    cursor: pointer;

    @media (min-width: ${PHONE_WIDTH}) {
        width: 10rem;
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        width: 15rem;
    }

    h4 {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
    }

    &:hover {
        h4 {
            color: rgba(14, 103, 175, 0.767);
        }
    }
`;

const Logo = styled(Link)`
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "Lobster Two", cursive;
`;

const FooterLink = styled.div`
    background-color: #eaeaea;
    width: 100%;
    justify-content: center;

    @media (min-width: ${PHONE_WIDTH}) {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 30px;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        height: 250px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
`;

const CopyrightContainer = styled.div`
    background-color: black;
    padding: 15px;
    margin: 0;
    width: 100%;

    h4 {
        color: white;
        font-size: 13px;
        font-weight: 400;
    }
`;


export default Footer;