import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

function LegalAndImprint() {
    return (
        <>
            <TitleContainer>
                <h1>Legal</h1>
            </TitleContainer>
            <ContentContainer>
                <h2>
                    LIABILITY FOR CONTENT
                </h2>
                <h3>
                    The information contained on this website is for general guidance on matters of interest only. Given the changing nature of laws, rules and regulations, and the inherent hazards of electronic communication, there may be delays, omissions or inaccuracies in information contained in this site. No legal, accounting, tax, or other professional advice and services are rendered on this website. As such, this website should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers. Before making any decision or taking any action, you should consult us and/or other professional advisors.
                    <br />
                    <br />
                    While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, we are not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in this site is provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. In no event will we be liable to you or anyone else for any decision made or action taken in reliance on the information on this website, insofar as the laws permit.
                    <br />
                    <br />
                    Obligations to remove or block the use of information in accordance with applicable laws remain unaffected by this. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove the relevant content immediately. Unless otherwise required by law, we disclaim all liability in relation to such infringements.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    LIABILITY FOR LINKS
                </h2>
                <h3>
                    Our website contains links to external websites of third parties over whose content we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognisable at the time of linking.
                    <br />
                    <br />
                    However, permanent monitoring of the content of the linked pages is not reasonable without concrete indications of a legal violation. If we become aware of any infringements of the law, we will remove such links immediately.
                    <br />
                    <br />
                    <br />
                </h3>
                <h2>
                    COPYRIGHT
                </h2>
                <h3>
                    The contents and works created by the site operators on these pages are subject to copyright. Duplication, processing, distribution, or any form of commercialization of such material  shall require the prior written consent of its respective author or creator, unless the relevant copyright law permits otherwise. Downloads and copies of this site are only permitted for private, non-commercial use.
                    <br />
                    <br />
                    Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.
                    <br />
                    <br />
                    Certain photos on this website are obtained from unsplash.com subject to their licence available here: <SLink to={{ pathname: "https://unsplash.com/license" }}> https://unsplash.com/license</SLink>.
                    <br />
                    <br />
                    <br />
                </h3>
            </ContentContainer>
        </>
    )
}

const TitleContainer = styled.div`
    width: 100%;
    padding: 36px;
    text-align: center;

    h1 {
        color: #000000;
        font-size: 52px;
        font-weight: 300;
    }
`;

const ContentContainer = styled.div`
    width: 100%;
    text-align: center;
    background-color: #bbcbd7;
    margin: auto;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 30px;

    h2 {
        color: #303133;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.75;
        text-align: left;
    }

    h3 {
        color: #777777;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.75;
        margin: 18px 0px 0px 0px;
        text-align: left;
    }
`;

const SLink = styled(Link)`
    color: #777777;
    text-decoration: none;
    cursor: pointer;

    &.active {
        h4 {
            color: rgba(48, 49, 51, 0.5);
        }
    }
`;

export default LegalAndImprint