import styled, { keyframes } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import useScrollSnap from '../functions/ScrollSnapEffect';
import { useEffect, useRef, useState } from 'react';
import { AnimatedImage } from '../component/CommonStyledComponents';
import { DESKTOP_WIDTH, PHONE_WIDTH } from '../constants/Dimensions';
import { isMobile } from 'react-device-detect';

const appearAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

//icon and text 
interface SeedImageProps {
    image: string;
}

const SeedOne = () => (
    <>
        <Seed>
            <SeedImage image="http://fruba-ip.com/wp-content/uploads/2023/09/1-reviewing-your-project-processes-black.png" />
            <h4>Analysis and audit</h4>
        </Seed>
    </>
);

const SeedTwo = () => (
    <>
        <Seed>
            <SeedImage image="http://fruba-ip.com/wp-content/uploads/2023/09/3-determining-long-term-strengths-and-challenges-black.png" />
            <h4>Inception and procurement</h4>
        </Seed>
    </>
);

const SeedThree = () => (
    <>
        <Seed>
            <SeedImage image="http://fruba-ip.com/wp-content/uploads/2023/09/6-commercialisation-black.png" />
            <h4>Monetisation and distribution</h4>
        </Seed>
    </>
);

const SeedFour = () => (
    <>
        <Seed>
            <SeedImage image="http://fruba-ip.com/wp-content/uploads/2023/09/4-realising-IPs-black.png" />
            <h4>Strategies and policies</h4>
        </Seed>
    </>
);

const SeedFive = () => (
    <>
        <Seed>
            <SeedImage image="http://fruba-ip.com/wp-content/uploads/2023/09/5-rights-management-black.png" />
            <h4>Protection and enforcement</h4>
        </Seed>
    </>
);
const SeedSix = () => (
    <>
        <Seed>
            <SeedImage image="http://fruba-ip.com/wp-content/uploads/2023/09/2-helping-build-your-IP-knowledge-black.png" />
            <h4>Education and training</h4>
        </Seed>
    </>
);

function Home() {

    const scrollRef = useRef(null);
    // The offset is the height of the menu
    const scrollOffset = isMobile ? 0 : 56;
    useScrollSnap({ ref: scrollRef, offset: scrollOffset });
    const [currentSection, setCurrentSection] = useState(0); // State to track the current section


    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 200);

        const handleKeyDown = (e: KeyboardEvent) => { // Explicitly type 'e' as 'KeyboardEvent'
            if (e.keyCode === 40) { // Down arrow key code
                // Calculate the next section to scroll to
                let nextSection = document.getElementById(`${currentSection + 1}-FlexBoxContainer`);
                if (nextSection) {
                    nextSection.scrollIntoView({ behavior: 'smooth' });
                    setCurrentSection(currentSection + 1); // Update the current section
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    return (
        <ScrollContainer ref={scrollRef}>
            <FlexBoxContainer id="0-FlexBoxContainer">
                <Landing>
                    <h1>From <b>Root</b> to <b>Fruit</b></h1>
                    <br />
                    <h2>
                        Nurturing your ideas with our expert intellectual property services

                    </h2>
                </Landing>
                {/* <FlexBox>
                    <AnimatedImage style={{ width: '85%', height: 'auto' }}
                        src="http://fruba-ip.com/wp-content/uploads/2021/12/Screenshot-2021-12-17-at-12.45.04-AM-e1702567292466.png"
                        alt="Fruba IP Consulting"
                    />
                </FlexBox> */}
            </FlexBoxContainer>

            <FlexBoxContainer id="1-FlexBoxContainer">
                <LeftAlignedImageFlexBox>
                    <Image id="animatedImage"
                        src="https://fruba-ip.com/wp-content/uploads/2021/12/Screenshot-2021-12-17-at-1.03.21-AM-uai-1032x1052.png"
                        alt="Fruba IP Consulting"
                    />
                </LeftAlignedImageFlexBox>
                <RightAlignedTextFlexBox>
                    <h3>
                        Fruba is your intellectual property consultancy dedicated to realising the full potential of your creative projects from start to finish. We offer comprehensive strategies and services that are tailored to your vision and long-term goals. We are there to advise and support when you take each step and make each decision.

                    </h3>
                    <br />
                    <h4>
                        Your ideas are seeds that need attentive care to grow and mature. Fruba is your guardian to make sure that your planning and execution are done right and well. Our goal is clear - bring your ideas and projects to success.

                    </h4>
                    <br />
                    <Link to={"/about-fruba"}>
                        <Button>READ MORE</Button>
                    </Link>
                </RightAlignedTextFlexBox>
            </FlexBoxContainer>

            <OurServiceContainer id="2-FlexBoxContainer">
                <h2>Our intellectual property services include</h2>
                <SeedContainer>
                    <SeedOne />
                    <SeedTwo />
                    <SeedThree />
                    <SeedFour />
                    <SeedFive />
                    <SeedSix />
                </SeedContainer>

                <Link to={"/what-we-do"}>
                    <Button>READ MORE</Button>
                </Link>

            </OurServiceContainer>
        </ScrollContainer>
    );
}

const Landing = styled.div`
    padding-top: 8%;
    text-align: center;
    align-items: center;
    width: 100%;

    h1, h2 {
        opacity: 0;
        animation: ${appearAnimation} 1.5s ease forwards;
        animation-delay: 0.5s;
    }

    @media (min-width: ${PHONE_WIDTH}) {
        padding-left: 7%;
        padding-right: 7%;
        margin: -30% 0 0 0;

        h1 {
            padding-top: 4%;
            color: #000000;
            font-size: 35px;
            font-weight: 300;
            text-align: center;
    
        }
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        width: 80%;
        padding-left: 3%;
        padding-right: 3%;
        margin: 0;

        h1 {
            padding-top: 4%;
            color: #000000;
            font-size: 70px;
            font-weight: 300;
            text-align: center;
        }
    }

    h2 {
        padding-top: 0%;
        color: #000000;
        font-size: 28px;
        font-weight: 300;
        text-align: center;
    }
`;

const Image = styled(AnimatedImage)`

    @media (min-width: ${PHONE_WIDTH}) {
        width: 55% !important;
        height: auto !important;
        max-height: 200px;
        padding-bottom:5%;

    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        width: 85% !important;
        height: auto !important;
        max-height: none;
        padding-top: 0;
    }
`;

const ScrollContainer = styled.div`
    // overflow-y: scroll;
    // scroll-snap-type: y mandatory; /* Enables vertical scroll snapping */
    // height: 100vh; /* Set to the height of the viewport */

`;

const FlexBoxContainer = styled.div`
    justify-content: center;
    align-items: center;
    align-items: start;
    margin: auto;
    scroll-snap-align: start; /* Ensures snapping aligns to the start of the element */
    height: 100vh; /*see if it works better if we take this out...Each child should be the height of the viewport */

    @media (min-width: ${PHONE_WIDTH}) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 5%;
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        height: 100vh;
    }
`;

const FlexBox = styled.div`
    padding-top: 2%;
    text-align: center;
    align-items: flex-start;

    width: 100%;


    @media (min-width: ${PHONE_WIDTH}) {
        padding-left: 7%;
        padding-right: 7%;

        h1 {
            padding-top: 4%;
            color: #000000;
            font-size: 35px;
            font-weight: 300;
            text-align: left;
        }

        h3 {
            font-size: 85%;
        }

       
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        width: 50%;
        padding-left: 3%;
        padding-right: 3%;

        h1 {
            padding-top: 4%;
            color: #000000;
            font-size: 52px;
            font-weight: 300;
            text-align: left;
        }

        h3 {
            font-size: 28px;
        }
    }

    h2 {
        padding-top: 4%;
        color: #000000;
        font-size: 28px;
        font-weight: 300;
        text-align: left;
    }

    h3 {
        color: #000000;
        font-weight: 300;
        text-align: left;
    }

    h4 {
        color: #000000;
        font-size: 55%;
        font-weight: 300;
        text-align: left;
    }

    img {
        height: 50%;
        max-width: 100%;
        width:auto;
        object-fit: cover;
        
    }
`;

const RightAlignedTextFlexBox = styled(FlexBox)`
    Button{
        margin-top:3%;
    }    
// Flip the order
    @media (min-width: ${PHONE_WIDTH}) {
        order: 1;
        padding-top:1%;

        h3{
            font-size:95%;
        }

        h4{
            font-size:82%;
            color: #777777;
            font-weight: 400;
        }

        Button{
            margin-top:0%;
        }  
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        order: 2;
        padding-top:2%;
        
        h3{
            font-size:120%;
        }

        h4{
            font-size:15px;
        }

        Button{
            margin-top:8%;
        }
    
    
    }
`;

const LeftAlignedImageFlexBox = styled(FlexBox)`

   
    // Flip the order
    @media (min-width: ${PHONE_WIDTH}) {
        order: 1;



    }

    @media (min-width: ${DESKTOP_WIDTH}) {
      
    }
`;

const OurServiceContainer = styled.div`
    width: 100%;
    background-color: #BECAD6;
    padding-top:5%;

    h2 {
        color: #000000;
        font-size: 28px;
        font-weight: 300;
        width:100%;
        padding-bottom:5%;
    }
   
   
    @media (min-width: ${PHONE_WIDTH}) {
        padding-bottom: 5%;
        height:100vh; 
     

        h2{
            font-size:130%;
            padding-left:3%;
            padding-right:3%;
        }
      
    }
    
    @media (min-width: ${DESKTOP_WIDTH}) {
        height:100vh;
    }
`;

const SeedContainer = styled.div`
    background-color: #BECAD6;
    position: relative;
    width: 100%;
    height:auto;
    display: grid;


    @media (min-width: ${PHONE_WIDTH}) {
        //flex-direction: row;
       padding-left:2%;
        padding-right:2%;
       // display: flex;
        //flex-wrap: wrap;
        align-item:center;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        padding-bottom:3%;
    }
    
    @media (min-width: ${DESKTOP_WIDTH}) {
        flex-direction: row;
        padding-left:15%;
        padding-right:15%;
        grid-template-columns: repeat(3, 1fr);
        padding-bottom:1%;
        row-gap: 20px;
        height: 75%;
    }


    > div{
        flex-basis: 0;

        @media (min-width: ${PHONE_WIDTH}) {
            //min-width: 30%;
        }
    
        @media (min-width: ${DESKTOP_WIDTH}) {
           // min-width: 23%; 
        }
    }
`;

const Seed = styled.div`
    align-items: center; /* Centers content horizontally in the flex container */
    text-align: center;
    margin: auto;
    padding: 5%;
    justify-content: center;
    box-sizing: border-box;

    h4 {
        font-weight: 400;
        font-size: 90%;
        color: #6e7071;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (min-width: ${PHONE_WIDTH}) {
        width: 100%;
        padding-bottom: 5%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        /* max-width: calc(100% / 3); */
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        padding-left: 8%;
        padding-right: 8%;
    }
`;


const SeedImage = styled.div<SeedImageProps>`
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    background-image: url(${props => props.image});
   
    @media (min-width: ${PHONE_WIDTH}) {
        width:80px;
        height:80px;
         
     }
`;

const Button = styled.button`
    font-weight: 600;
    background-color: #303133;
    border-color: #303133;
    color: white;
    border-width: 1px;
    border-radius: 2px;
    box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.6);
    padding: 13px 31px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0,0,0,0);
        color: black;
    }

    @media (min-width: ${PHONE_WIDTH}) {
       font-size:11px;


     }
  
`;






export default Home