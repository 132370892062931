import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import WhatWeDo from './WhatWeDo';
import ContactUs from './ContactUs';
import About from './About';
import LegalAndImprint from './LegalAndImprint';
import PrivacyPolicy from './PrivacyPolicy';
import NewsCollection from './NewsCollection';
import NewsArticle from './NewsArticle';

function PageRouters() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about-fruba' element={<About />} />
            <Route path='/what-we-do' element={<WhatWeDo />} />
            <Route path='/news' element={<NewsCollection />} />
            <Route path='/news/:id?' element={<NewsArticle />} />
            {/* <Route path='/news/:author?/:category?/:month?' element={<NewsCollection />} /> */}
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/imprint' element={<LegalAndImprint />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
        </Routes>
    )
}

export default PageRouters