import React, { useRef, useState, useEffect } from 'react';
import useScrollSnap from '../functions/ScrollSnapEffect';
import styled from 'styled-components/macro';
import './WhatWeDo.css';
import { DESKTOP_WIDTH, PHONE_WIDTH } from '../constants/Dimensions';

const FlexBoxContainer = styled.div`

`
//transparent black overlay underneath the popup box
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); // Semi-transparent black
    z-index: 999; // Ensure it's below the popup but above everything else
`;

//pop up bpx
const PopupBox = styled.div`
    position: fixed;
    background-color: rgb(204, 204, 205);
    padding: 50px;
    z-index: 1000;
    bottom: 10%;
    animation: 1s ease 0s 1 normal forwards running popUp;
    display: flex;
    left:0;
    top:10%;

    @media (min-width: ${PHONE_WIDTH}){
        width:auto;
        height:auto;
        bottom: 2%;
        top: 3%; 
        left:5%;
         right: 5%;
        
    }

    @media (min-width: ${DESKTOP_WIDTH}) {
        width:auto;
        height:auto;
        top: 15%;
        left: 15%;
        right:15%;
    }

    @media (min-width: 1920px) {
        top: 15%;
        left: 25%;
        transform: translate(-50%, -50%);
        width: 1000px;  // Adjust if needed
        height: 700px;
    }
`;
//close button of the pop up box
const CloseButton = styled.button`
  position: absolute;
  right: 30px;
  top: 40px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #686868;
`;

const content1 = (
    <div className="popup-content">
        <div className="popup-icon-grid">
            <img src="http://fruba-ip.com/wp-content/uploads/2023/09/1-reviewing-your-project-processes-black.png"
                alt="Icon1" className="popup-icon-img" />
            <div className="popup-icon-title">Analysis and audit</div>
        </div>
        <ul className="expanded-text">
            <li>IP portfolio assessment, evaluation and optimisation</li>
            <li>Trademark strategy evaluation</li>
            <li>IP due diligence for acquisition and investments</li>
            <li>Existing IP policy review</li>
            <li>Freedom-to-Operate (FTO) analysis</li>
            <li>IP valuation</li>
            <li>Compliance and regulatory analysis for specific industries</li>
        </ul>
    </div>
);

const content2 = (
    <div className="popup-content">
        <div className="popup-icon-grid">
            <img src="http://fruba-ip.com/wp-content/uploads/2023/09/3-determining-long-term-strengths-and-challenges-black.png"
                alt="Icon2" className="popup-icon-img" />
            <div className="popup-icon-title">Inception and procurement</div>
        </div>
        <ul className="expanded-text">
            <li>IP availability searches</li>
            <li>Pre-use advice</li>
            <li>IP filings, registration and office actions</li>
            <li>Filing strategy</li>
            <li>Trade secret and confidentiality protection</li>
            <li>Third-party right procurement and clearance</li>
        </ul>
    </div>
);


const content3 = (
    <div className="popup-content">
        <div className="popup-icon-grid">
            <img src="https://fruba-ip.com/wp-content/uploads/2023/09/6-commercialisation-black.png"
                alt="Icon3" className="popup-icon-img" />
            <div className="popup-icon-title">Monetisation and distribution</div>
        </div>
        <ul className="expanded-text">
            <li>Asset bundling, branding and marketing</li>
            <li>Licensing modelling, preparation and template drafting</li>
            <li>Review, negotiation and execution of licensing agreements</li>
            <li>Royalty management advice</li>
            <li>IP investment and sale advice</li>
            <li>Partnership and collaboration arrangement</li>
            <li>Brand and content licensing and distribution</li>
        </ul>
    </div>
);

const content4 = (
    <div className="popup-content">
        <div className="popup-icon-grid">
            <img src="https://fruba-ip.com/wp-content/uploads/2023/09/4-realising-IPs-black.png"
                alt="Icon4" className="popup-icon-img" />
            <div className="popup-icon-title">Protection and enforcement</div>
        </div>
        <ul className="expanded-text">
            <li>Market and competitive intelligence</li>
            <li>Top-level and global IP strategy aligned with business goals</li>
            <li>Lifecycle planning</li>
            <li>IP risk management and mitigation</li>
            <li>IP structuring</li>
            <li>Continuous portfolio management and review</li>
            <li>Internal IP policies and guidelines such as inventor, and employee and use policies</li>
        </ul>
    </div>
);

const content5 = (
    <div className="popup-content">
        <div className="popup-icon-grid">
            <img src="http://fruba-ip.com/wp-content/uploads/2023/09/5-rights-management-black.png"
                alt="Icon5" className="popup-icon-img" />
            <div className="popup-icon-title">Strategies and policies</div>
        </div>
        <ul className="expanded-text">
            <li>IP monitoring and surveillance</li>
            <li>Cease and desist actions</li>
            <li>Contentious action advice and coordination</li>
            <li>Domain name dispute resolution</li>
            <li>IP mediation and arbitration</li>
            <li>Strategic enforcement strategies</li>
            <li>Licensing arrangement compliance review</li>
        </ul>
    </div>
);

const content6 = (
    <div className="popup-content">
        <div className="popup-icon-grid">
            <img src="https://fruba-ip.com/wp-content/uploads/2023/09/2-helping-build-your-IP-knowledge-black.png"
                alt="Icon6" className="popup-icon-img" />
            <div className="popup-icon-title">Education and training</div>
        </div>
        <ul className="expanded-text">
            <li>Training for employees and contractors</li>
            <li>IP introduction and tips for artists and inventors</li>
            <li>IP sessions for entrepreneurs and start-ups</li>
            <li>Legal professional IP workshops</li>
            <li>Legal development updates</li>
            <li>Customised workshops and programmes</li>
        </ul>
    </div>
);

//Carousel
const images = [
    'http://fruba-ip.com/wp-content/uploads/2023/12/Screenshot-2023-12-13-at-09.02.01.png',
    'http://fruba-ip.com/wp-content/uploads/2023/12/Screenshot-2023-12-13-at-09.02.32.png',
    'http://fruba-ip.com/wp-content/uploads/2023/12/Screenshot-2023-12-13-at-09.01.51.png',
    'http://fruba-ip.com/wp-content/uploads/2023/12/Screenshot-2023-12-13-at-09.01.45.png'
];

const leftArrowImage = 'http://fruba-ip.com/wp-content/uploads/2023/12/leftarrow.png'; // Path to your left arrow image
const rightArrowImage = 'http://fruba-ip.com/wp-content/uploads/2023/12/rightarrow.png'; // Path to your right arrow image


const Carousel: React.FC = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstImage = currentImageIndex === 0;
        const newIndex = isFirstImage ? images.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(newIndex);
    };

    const goToNext = () => {
        const isLastImage = currentImageIndex === images.length - 1;
        const newIndex = isLastImage ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(newIndex);
    };

    return (
        <div className="carousel">
            <div className="carousel-wrapper">
                <button onClick={goToPrevious} className="carousel-button left-button">
                    <img className="carousel-button-image" src={leftArrowImage} alt="Previous" />
                </button>
                <img src={images[currentImageIndex]} alt="Testimonials" />
                <button onClick={goToNext} className="carousel-button right-button">
                    <img className="carousel-button-image" src={rightArrowImage} alt="Next" />
                </button>
            </div>
        </div>
    );
};

function WhatWeDo() {
    const scrollRef = useRef(null);
    const [popupContent, setPopupContent] = useState<JSX.Element | null>(null);

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 200);
    }, []);

    useScrollSnap({ ref: scrollRef, offset: 56 });

    const handleIconClick = (content: JSX.Element) => {
        setPopupContent(content);
    }

    const handleClosePopup = () => {
        setPopupContent(null);
    }

    return (
        <FlexBoxContainer className="fullframe" ref={scrollRef}>
            {/* Overlay */}
            {popupContent && <Overlay />}
            <div className="what-we-do-mainframe">
                <div className="column-1">
                    <img
                        decoding="async"
                        className="what-we-do-pic"
                        src="http://fruba-ip.com/wp-content/uploads/2021/12/Screenshot-2021-12-08-at-5.46.49-PM-e1702567652459.png"
                        alt="What we do"
                    />
                </div>
                <div className="column-2">
                    <div className="title">What We Do</div>
                    <div className="what-we-do-text">
                        <p>
                            Fruba delivers complete and thorough IP services, characterised by a strategic yet pragmatic approach to your projects.

                        </p>
                    </div>
                </div>
            </div>

            <div className="grid-container">
                <div className="grid-title">
                   Our core IP services are:
                </div>

                <div className="grid-item grid-item-1" onClick={() => handleIconClick(content1)}>
                    <img className="icons" alt="Icon1" src="http://fruba-ip.com/wp-content/uploads/2023/09/1-reviewing-your-project-processes-black.png"
                    />
                    <div className="grid-item-text">Analysis and audit</div>
                </div>
                <div className="grid-item grid-item-2" onClick={() => handleIconClick(content2)}>
                    <img className="icons" alt="Icon2" src="http://fruba-ip.com/wp-content/uploads/2023/09/3-determining-long-term-strengths-and-challenges-black.png"
                    />
                    <div className="grid-item-text">Inception and procurement</div>
                </div>
                <div className="grid-item grid-item-3" onClick={() => handleIconClick(content3)}>
                    <img className="icons" alt="Icon3" src="
                    http://fruba-ip.com/wp-content/uploads/2023/09/6-commercialisation-black.png"
                    />
                    <div className="grid-item-text">Monetisation and distribution</div>
                </div>
                <div className="grid-item grid-item-4" onClick={() => handleIconClick(content4)}>
                    <img className="icons" alt="Icon4" src="http://fruba-ip.com/wp-content/uploads/2023/09/4-realising-IPs-black.png"
                    />
                    <div className="grid-item-text">Protection and enforcement</div>
                </div>
                <div className="grid-item grid-item-5" onClick={() => handleIconClick(content5)}>
                    <img className="icons" alt="Icon5" src="http://fruba-ip.com/wp-content/uploads/2023/09/5-rights-management-black.png"
                    />
                    <div className="grid-item-text">Strategies and policies</div>
                </div>
                <div className="grid-item grid-item-6" onClick={() => handleIconClick(content6)}>
                    <img className="icons" alt="Icon6" src="http://fruba-ip.com/wp-content/uploads/2023/09/2-helping-build-your-IP-knowledge-black.png"
                    />
                    <div className="grid-item-text">Education and training</div>
                </div>
            </div>
            <div className="what-we-do-text">Fruba takes a customised approach to the client’s needs. Please <a href="https://fruba-ip.com/contact-us/">contact us</a> so that we understand your questions and vision, and provide you with our preliminary recommendations.</div>

            <Carousel />

            {popupContent && (
                <PopupBox className="pop-up-box">
                    <CloseButton onClick={handleClosePopup}><img src="http://fruba-ip.com/wp-content/uploads/2023/11/closebutton.png" className="close-button" /></CloseButton>
                    {popupContent}
                </PopupBox>
            )}
        </FlexBoxContainer>
    );
}

export default WhatWeDo;
