import React, { useState, useEffect, useRef } from 'react';
import './About.css';
import '../index.css';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import { UserData, fetchAllActiveUsers } from '../data/UserData';
import { ToastContainer } from 'react-toastify';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { Colors } from "../constants/Colors";
import { hideLoadingBar, showLoadingBar } from "../functions/Widgets";

<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

type FounderProps = {
    name: string;
    description: string;
    linkedInLink: string;
};

const FounderBlock: React.FC<FounderProps> = ({ name, description, linkedInLink }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleImageSrc = isVisible
        ? "https://fruba-ip.com/wp-content/uploads/2021/12/Minus.svg"
        : "https://fruba-ip.com/wp-content/uploads/2021/12/Plus.svg";

    return (
        <div className={name.toLowerCase().replace(' ', '-') + "-block"}>
            <div className="founder-header">
                <div className="founder-name">{name}</div>
                <img
                    className="icon-toggle"
                    src={toggleImageSrc}
                    onClick={() => setIsVisible(!isVisible)}
                    alt="Toggle Description"
                    style={{ cursor: 'pointer' }}
                />
            </div>

            <FounderDescription name={name} description={description} isVisible={isVisible} />

            <div className="linkedinlogo">
                <SLink to={linkedInLink}>
                    <FaLinkedin size={30} style={{ fill: 'black' }} />
                </SLink>
            </div>
        </div>
    );
};

const FounderDescription: React.FC<{ name: string, description: string, isVisible: boolean }> = ({ name, description, isVisible }) => {

    return (
        <div
            className={`collapsible-body ${isVisible ? 'expanded' : ''}`}
            dangerouslySetInnerHTML={{ __html: description }}></div>
    );
};

const SLink = styled(NavLink)`
    display: flex;
    flex-direction: row;
    margin-right: 2rem;
    text-decoration: none;
    width: 15rem;
    height: 2rem;
    list-style: circle;
    cursor: pointer;

    h4 {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
    }

    &:hover {
        h4 {
            color: rgba(14, 103, 175, 0.767);
        }
    }
`;

function About() {

    const [users, setUsers] = useState<UserData[]>();

    const loadingRef: React.MutableRefObject<LoadingBarRef | null> = useRef(null);

    useEffect(() => {
        showLoadingBar(loadingRef);

        const fetchUsers = async () => {

            const users = (await fetchAllActiveUsers());
            setUsers(users);
            hideLoadingBar(loadingRef);
            window.scrollTo(0, 0)
        };

        fetchUsers();
    }, []);

    return (
        <div>
            <LoadingBar color={Colors.LoadingBarColor} ref={loadingRef} />

            <div className="section limit-width about_pic_section">
                <img
                    decoding="async"
                    className="about_pic pop-in-animation"
                    src="https://fruba-ip.com/wp-content/uploads/2021/12/Felix-and-Nina-scaled.jpg"
                    width="100%"
                    height="auto"
                    alt="Founders of Fruba"
                />
            </div>

            <div className="section limit-width mainframe">

                <div className="heading">
                    About Fruba

                </div>
                <div className="about_text">
                    <p>Fruba stands as a leading intellectual property consultancy, offering pragmatic solutions to meet clients’ needs concerning their creative products and innovations. Our comprehensive range of services encompasses the entire spectrum, from idea inception and strategic planning to commercialisation and the protection of intellectual assets.
                        <br></br><br></br></p>
                    <p>At the core of our company's ethos lies the cultivation of meaningful client relationships, with an unwavering commitment to being your trusted partner right from the outset of your IP journey.
                        <br></br><br></br></p>
                    <p>With extensive experience spanning Europe and Asia, Fruba ensures that our IP expertise seamlessly integrates into our clients’ strategic decision-making processes. Our dedicated approach grants us a profound understanding of your distinct requirements and a vested interest in the ultimate success of your project.
                        <br></br><br></br></p>
                    <p>Fruba adopts a proactive stance throughout every phase of your project, addressing IP concerns as they arise. Our primary objective is to unlock the full potential of our clients' knowledge, creations, and innovations. We place great emphasis on the development of long-term strategies and policies, ensuring that our clients are well-equipped to manage their IP long after our involvement.
                        <br></br><br></br></p>
                    <p>Our IP expertise covers:<br></br><br></br></p>
                </div>
                <div className="about_text_list">
                    <ul className="left-list">
                        <li className="list-item-with-image">Idea inception and project preparation</li>
                        <li className="list-item-with-image">Filings and registrations</li>
                        <li className="list-item-with-image">Portfolio management</li>
                        <li className="list-item-with-image">Brand and marketing</li>
                        <li className="list-item-with-image">Commercial licensing and contracting</li>
                    </ul>
                    <ul className="right-list">
                        <li className="list-item-with-image">Right procurement</li>
                        <li className="list-item-with-image">Strategies and policy making</li>
                        <li className="list-item-with-image">Enforcement and disputes</li>
                        <li className="list-item-with-image">New technology adoption</li>
                    </ul>

                    <ul className="combined-list">
                        <li className="list-item-with-image-2">Idea inception and project preparation</li>
                        <li className="list-item-with-image-2">Filings and registrations</li>
                        <li className="list-item-with-image-2">Portfolio management</li>
                        <li className="list-item-with-image-2">Brand and marketing</li>
                        <li className="list-item-with-image-2">Commercial licensing and contracting</li>
                        <li className="list-item-with-image-2">Right procurement</li>
                        <li className="list-item-with-image-2">Strategies and policy making</li>
                        <li className="list-item-with-image-2">Enforcement and disputes</li>
                        <li className="list-item-with-image-2">New technology adoption</li>
                    </ul>
                </div>

                <div className="about_text">
                    <p><br></br>We have substantial experience in the media, consumer brand, entertainment, technology and education industries.
                    </p>
                </div>

                <div className="founder-container">

                    {users?.map((user) => {
                        return (
                            <div className="founder-entry" key={"User" + user.id}>

                                <div className="founderphotos">

                                    <div>
                                        <img
                                            decoding="async"
                                            className="founders pop-in-animation"
                                            src={user.profile_image_url}
                                            width="100%"
                                            height="auto"
                                            alt={user.name}
                                        />
                                    </div>

                                </div>

                                <div className="founders-section">
                                    <FounderBlock
                                        name={user.name}
                                        description={user.acf.biography}
                                        linkedInLink={user.acf.linkedin_url}
                                    />
                                </div>

                            </div>

                        )
                    })}

                </div>

            </div>

            <ToastContainer />
        </div >
    );
}

export default About;
