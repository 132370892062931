import React from 'react'
import styled from 'styled-components/macro';
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { News, getMostRecentNews, ArchiveMonth, getAllArchiveMonths } from "../data/NewsData";

function RecentPostsAndArchives() {

    const [recentNews, setRecentNews] = useState<News[]>([]);
    const [archives, setRecentsetArchivesNews] = useState<ArchiveMonth[]>([]);
    let params = useParams();

    useEffect(() => {
        const fetchNews = async () => {
            const newsData = await getMostRecentNews(3);
            setRecentNews(newsData);
        };

        const fetchArchives = async () => {
            const archives = await getAllArchiveMonths();
            setRecentsetArchivesNews(archives);
        };

        fetchNews();
        fetchArchives();
    }, []);

    return (
        <ContentContainer>
            <h2>
                Recent posts
            </h2>
            {recentNews.map((newsItem) => {
                return (
                    <h3 key={newsItem.id}><StyledLink to={`/news/${newsItem.id}`}>{newsItem.title.rendered}</StyledLink></h3>
                );
            })}
            <br />
            <br />
            <h2>
                Archives
            </h2>
            {archives.map((archive) => {
                const postStr = archive.NumResults > 1 ? "Posts" : "Post";
                const dateStr = archive.Month + ' ' + archive.Year.toString();
                return (
                    <h3 key={dateStr}>
                        <StyledLink to={"/news?month=" + archive.Month + "&year=" + archive.Year.toString()}>
                            {dateStr} ({archive.NumResults} {postStr})
                        </StyledLink></h3>
                );
            })}

        </ContentContainer>
    )
}

const ContentContainer = styled.div`
    width: 100%;
    margin: auto;
    padding-top: 30px;
    line-height: 2;

    h2 {
        color: #303133;
        font-size: 29px;
        font-weight: 600;
        line-height: 1.75;
        text-align: left;
        
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #303133;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    line-height: 2;
    margin-top: 15px;

    &:focus, &:visited, &:link, &:active {
        text-decoration: none;
    }

    &:hover {
        color: #006cff
    }
`;

export default RecentPostsAndArchives